/* This Source Code Form is subject to the terms of the Mozilla Public
 * License, v. 2.0. If a copy of the MPL was not distributed with this
 * file, You can obtain one at http://mozilla.org/MPL/2.0/. */

/**
 * List of SMS message IDs
 */

export default {
  FIREFOX_MOBILE_INSTALL: 1,
};
